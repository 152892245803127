import {Injectable} from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    loggedUser = new BehaviorSubject<any>(null);
    userProfile = new BehaviorSubject(null);
    userRequired = false;
    previouslyLogged;
    lastRequest;

    constructor() {
    }

    loginUser(email: string, password: string): Promise<firebase.auth.UserCredential> {
        return firebase.auth().signInWithEmailAndPassword(email, password);
    }


    async loginGoogle() {
        // Using a popup.
        const provider = new firebase.auth.GoogleAuthProvider();
        const user = await firebase.auth().signInWithPopup(provider);
        const profileDB = await firebase.firestore().collection('userProfile').doc(user.user.uid).get();
        if (!profileDB.exists) {
            firebase.firestore().collection('userProfile').doc(user.user.uid).set({email: user.user.email});
        }
        return;
    }

    resetPassword(email: string): Promise<void> {
        return firebase.auth().sendPasswordResetEmail(email);
    }

    logoutUser(): Promise<void> {
        return firebase.auth().signOut();
    }

    signupUser(email: string, password: string): Promise<any> {
        return new Promise((resolve, reject) => {
            firebase.auth().createUserWithEmailAndPassword(email, password)
                .then((newUserCredential: firebase.auth.UserCredential) => {
                    newUserCredential.user.sendEmailVerification();
                    firebase.firestore().collection('userProfile').doc(newUserCredential.user.uid).set({email});
                    resolve(newUserCredential.user);
                })
                .catch(error => {
                    console.log(error);
                    reject(error);
                });
        });
    }

    updateProfile(profile) {
        this.getUser().getValue().updateProfile(profile);
    }

    changePassword(oldPassword, newPassword) {
        return new Promise((resolve, reject) => {
            firebase.auth().signInWithEmailAndPassword(this.loggedUser.getValue().email, oldPassword).then(user => {
                    this.loggedUser.getValue().updatePassword(newPassword).then(() => resolve());
                },
                error => {
                    reject(error);
                });
        });

    }

    async getProfile(user ?) {
        if (!user) {
            user = this.loggedUser.getValue();
        }
        const profile = await firebase.firestore().collection('userProfile').doc(user.uid).get();
        if (profile.exists) {
            this.userProfile.next({
                email: user.email,
                ...profile.data(),
                id: user.uid,
                uid: user.uid,
                emailVerified: user.emailVerified
            });
        } else {
            this.userProfile.next({
                id: user.uid,
                uid: user.uid,
                email: user.email,
                emailVerified: user.emailVerified,
                pending: true
            });
        }
    }

    getUser() {
        if (!this.userRequired) {
            this.userRequired = true;
            firebase.auth().onAuthStateChanged(async user => {
                this.loggedUser.next(user);
                if (user) {
                    await this.getProfile(user);
                    this.previouslyLogged = true;
                } else if (this.previouslyLogged) {
                    // Flush all caches
                    window.location.href = '/';
                }
            });
        }
        return this.loggedUser;
    }


    async resendEmailVerification() {
        const user = this.loggedUser.getValue();
        await user.sendEmailVerification();
    }

    getUserProfile() {
        this.getUser();
        return this.userProfile;
    }


}

