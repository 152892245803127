import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/firestore';



@Injectable({
    providedIn: 'root'
})
export class ConfirmacionService {

    inscripcionesCollection = firebase.firestore().collection('inscripciones');
    mailCollection = firebase.firestore().collection('mail');
    pago = false;
    constructor() {
    }

    responsable = {};
    inscripciones = [];

    setData(responsable, inscripciones) {
        this.responsable = responsable;
        this.inscripciones = inscripciones;
    }

    getPrice() {
        let precio = 0;
        this.inscripciones.forEach(inscripcion => {
            if (inscripcion.asociada) {
                precio += 7;
            } else {
                precio += 12;
            }
        });
        return precio;
    }

    getData() {
        return { inscripciones: this.inscripciones, responsable: this.responsable, pago: this.pago };
    }

    storeConfirmation(paymentInfo) {
        let emails = this.inscripciones.map(inscripcion => inscripcion.email);
        emails = emails.concat(this.inscripciones.map(inscripcion => inscripcion.emailpadres));
        emails.push(this.responsable['email']);
        this.pago = true;
        return this.inscripcionesCollection.add({
            paymentInfo,
            to: emails,
            bcc: ['info@fundacionaltum.org'],
            template: {
                name: 'csCwPU3MqRpkhNUSTUUJ',
                data: {
                    inscripciones: this.inscripciones,
                    responsable: this.responsable,
                    transferencia: paymentInfo['transferencia'],
                    precio: this.getPrice()
                }
            }
        });
    }
}
