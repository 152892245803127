import {ConfirmacionService} from './confirmacion.service';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {ComponentsModule} from './components/components.module';

import {ServiceWorkerModule} from '@angular/service-worker';

import {environment} from '../environments/environment';


@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        ComponentsModule,
        ServiceWorkerModule.register('/ngsw-worker.js', {enabled: environment.production})
    ],
    providers: [
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        ConfirmacionService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
