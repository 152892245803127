import {Component} from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/performance';
import 'firebase/analytics';
import {AuthService} from './auth.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: [
        './side-menu/styles/side-menu.scss',
        './side-menu/styles/side-menu.shell.scss',
        './side-menu/styles/side-menu.responsive.scss'
    ]
})
export class AppComponent {
    user: any;

    constructor(private auth: AuthService) {
        this.initializeApp();
        const firebaseConfig = {
            apiKey: 'AIzaSyAf9Pu0D8aCjmuHCdaJVpoWSWFcZ1a8vwg',
            authDomain: 'altum-pwa.firebaseapp.com',
            databaseURL: 'https://altum-pwa.firebaseio.com',
            projectId: 'altum-pwa',
            storageBucket: 'altum-pwa.appspot.com',
            messagingSenderId: '930568260191',
            appId: '1:930568260191:web:7b801ad829ea6b6ef0a9ea',
            measurementId: 'G-41C9B54DBB'
        };
        firebase.initializeApp(firebaseConfig);
        firebase.performance();

        this.auth.getUserProfile().subscribe(async user => {
            this.user = user;
        });
    }

    initializeApp() {
    }

    logout() {
        this.auth.logoutUser();
    }
}
