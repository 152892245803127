import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;

const routes: Routes = [
    {path: '', redirectTo: subdomain === 'takeoff' ? '/take-off' : '/campamentos', pathMatch: 'full'},
    //{path: '', redirectTo: '/campamentos', pathMatch: 'full'},
    {path: '', redirectTo: '/take-off', pathMatch: 'full'},
    {path: '', loadChildren: './campamentos/campamentos.module#CampamentosPageModule'},
    {path: 'walkthrough', loadChildren: './walkthrough/walkthrough.module#WalkthroughPageModule'},
    {path: 'getting-started', loadChildren: './getting-started/getting-started.module#GettingStartedPageModule'},
    {path: 'login', loadChildren: './login/login.module#LoginPageModule'},
    {path: 'signup', loadChildren: './signup/signup.module#SignupPageModule'},
    {path: 'auth/forgot-password', loadChildren: './forgot-password/forgot-password.module#ForgotPasswordPageModule'},
    {path: 'contact-card', loadChildren: './contact-card/contact-card.module#ContactCardPageModule'},
    {
        path: 'forms-and-validations',
        loadChildren: './forms/validations/forms-validations.module#FormsValidationsPageModule'
    },
    {path: 'forms-filters', loadChildren: './forms/filters/forms-filters.module#FormsFiltersPageModule'},
    {path: 'page-not-found', loadChildren: './page-not-found/page-not-found.module#PageNotFoundModule'},
    {path: 'take-off', loadChildren: './take-off/take-off.module#TakeOffModule'},
    {path: 'pago', loadChildren: './pago/pago.module#PagoPageModule'},
    {path: 'transferencia', loadChildren: './transferencia/transferencia.module#TransferenciaPageModule'},
    {
        path: 'inscripcion-take-off',
        loadChildren: './inscripcion-take-off/inscripcion-take-off.module#InscripcionTakeOffModule'
    },
    {
        path: 'confirmacion-take-off',
        loadChildren: './confirmacion-take-off/confirmacion-take-off.module#ConfirmacionTakeOffModule'
    },
    {
        path: 'confirmacion-pago-take-off',
        loadChildren: './confirmacion-take-off/confirmacion-take-off.module#ConfirmacionTakeOffModule'
    },
    {path: 'showcase', loadChildren: './showcase/showcase.module#ShowcasePageModule'},
    {path: 'categories', loadChildren: './categories/categories.module#CategoriesPageModule'},
    {path: 'campamentos', loadChildren: './campamentos/campamentos.module#CampamentosPageModule'},
    {
        path: 'campamento/:campamentoId',
        loadChildren: './campamento-detalle/campamento-detalle.module#CampamentoDetallePageModule'
    },
    {path: 'crear-campamento', loadChildren: './crear-campamento/crear-campamento.module#CrearCampamentoPageModule'},
    {
        path: 'crear-campamento/:cloneId',
        loadChildren: './crear-campamento/crear-campamento.module#CrearCampamentoPageModule'
    },
    {path: 'editar/:editId', loadChildren: './crear-campamento/crear-campamento.module#CrearCampamentoPageModule'},
    {path: 'crear-usuario', loadChildren: './crear-usuario/crear-usuario.module#CrearUsuarioPageModule'},
    // tslint:disable-next-line:max-line-length
    {
        path: 'inscripcion-campamentos/:campamentoId/:inscriptionType',
        loadChildren: './inscripcion-campamentos/inscripcion-campamentos.module#InscripcionCampamentosPageModule'
    },
    {
        path: 'inscripcion/inscripcion-campamentos/:inscripcionId',
        loadChildren: './inscripcion-campamentos/inscripcion-campamentos.module#InscripcionCampamentosPageModule'
    },
    {
        path: 'inscripcion/inscripcion-campamentos/editar/:inscripcionEditarId',
        loadChildren: './inscripcion-campamentos/inscripcion-campamentos.module#InscripcionCampamentosPageModule'
    },
    {
        path: 'inscripcion/inscripcion-campamentos/:inscripcionId/:paymentResult',
        loadChildren: './inscripcion-campamentos/inscripcion-campamentos.module#InscripcionCampamentosPageModule'
    },
    {
        path: 'admin-campamentos',
        loadChildren: './campamentos-admin/campamentos-admin.module#CampamentosAdminPageModule'
    },
    {
        path: 'admin-inscripciones',
        loadChildren: './inscripciones-admin/inscripciones-admin.module#InscripcionesAdminPageModule'
    },
    {
        path: 'take-off-admin',
        loadChildren: './take-off-list/take-off-list.module#TakeOffListPageModule'
    },
    {
        path: 'takeoff-i/:takeOffId',
        loadChildren: './take-off-view/take-off-view.module#TakeOffViewPageModule'
    },
    {
        path: 'admin-inscripcion/:campamentoId',
        loadChildren: './inscripcion-admin/inscripcion-admin.module#InscripcionAdminPageModule'
    },
    {
        path: 'inscripciones',
        loadChildren: './inscripcion-user/inscripcion-user.module#InscripcionUserPageModule'
    },
    {
        path: 'admin-usuarios',
        loadChildren: './usuarios-admin/usuarios-admin.module#UsuariosAdminPageModule'
    },
    {
        path: 'admin-asociaciones',
        loadChildren: './asociaciones-admin/asociaciones-admin.module#AsociacionesAdminPageModule'
    },
    {path: '**', redirectTo: 'page-not-found'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
